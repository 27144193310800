import React, { useEffect } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { ApprovalStatus, CampaignApprovalStatusLabel, GenericEntity, OrderDirection } from 'utils/types';
import {
  CampaignAlert,
  CampaignAlertLabel,
  CampaignApprovalStatuses,
  CampaignType,
  CampaignTypeLabel,
} from 'utils/types/campaigns';
import { useSelector } from 'react-redux';
import { initialState, priorityState } from 'app/genericSlices/campaigns';
import { vceCampaignsPage, doeCampaignsPage } from 'app/genericSlices/campaigns';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import { CampaignsFilterBarProps } from 'pages/campaigns/campaignManagement/components/campaignsFilterBar/CampaignsFilterBar.consts';
import { marketConfig } from 'app/slices/config';
import { useQuery } from '@apollo/client';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { ButtonText } from 'components/shared/button';
import { LocationSet } from 'utils/types/locations';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { store } from 'app/store';
import { Container, IconWrapper, StyledCheckbox, StyledSearch } from './CampaignsFilterBar.style';
import { getInitialCampaignZones, nationalSelectItem, onCampaignZoneChange } from 'utils/location';
import { FetchPolicies } from 'utils/types/common';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { useBaseOfferRecsQuery } from 'hooks/base-offer-recs';
import { useOfferBanksQuery } from 'hooks/offer-banks';
import { BaseOfferRec, OfferBank } from 'app/genericSlices/offers';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';

const resetFilters = (filterWithClear: any, onSortModelChange: any) => {
  filterWithClear(CampaignFilterType.Status, initialState.filters[CampaignFilterType.Status]);
  onSortModelChange({ externalId: OrderDirection.DESC, id: OrderDirection.DESC }, [
    { field: 'externalId', sort: 'desc' },
  ]);
};

const handleToggle = (filters: any, filterMulti: any, filterWithClear: any, onSortModelChange: any) => {
  const newPriorityValue = filters[CampaignFilterType.Priority] === 'true' ? 'false' : 'true';
  const campaignsPage = vceCampaignsPage;
  store.dispatch(
    campaignsPage.actions.setFilter({
      filter: CampaignFilterType.Priority,
      value: newPriorityValue,
    }),
  );

  if (newPriorityValue === 'true') {
    filterMulti({
      [CampaignFilterType.Status]: priorityState.filters[CampaignFilterType.Status],
      [CampaignFilterType.Type]: priorityState.filters[CampaignFilterType.Type],
      [CampaignFilterType.Tags]: priorityState.filters[CampaignFilterType.Tags],
      [CampaignFilterType.Alert]: priorityState.filters[CampaignFilterType.Alert],
      [CampaignFilterType.SearchQuery]: priorityState.filters[CampaignFilterType.SearchQuery],
      [CampaignFilterType.ExcludedExternalIds]: priorityState.filters[CampaignFilterType.ExcludedExternalIds],
      [CampaignFilterType.Period]: priorityState.filters[CampaignFilterType.Period],
      [CampaignFilterType.Zone]: priorityState.filters[CampaignFilterType.Zone],
      [CampaignFilterType.OnlyLocal]: priorityState.filters[CampaignFilterType.OnlyLocal],
      [CampaignFilterType.National]: priorityState.filters[CampaignFilterType.National],
      [CampaignFilterType.Priority]: true,
    });
  } else {
    resetFilters(filterWithClear, onSortModelChange);
  }
};
const CampaignsFilterBar = ({
  offerSource,
  filter,
  filterMulti,
  filterWithClear,
  forceStatusFilter,
  onSortModelChange,
}: CampaignsFilterBarProps) => {
  const campaignPage = offerSource === OfferSource.VCE ? vceCampaignsPage : doeCampaignsPage;
  const { filters } = useSelector(campaignPage.campaignsState);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Campaign]);
  const { load: loadBaseOfferRecs, baseOfferRecs } = useBaseOfferRecsQuery(true);
  const { load: loadOfferBanks, offerBanks } = useOfferBanksQuery(true);

  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[CampaignFilterType.SearchQuery]);
  const { config } = useSelector(marketConfig);
  const { data: periodsData } = useQuery(periodsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: { filters: {}, order: { startDate: OrderDirection.DESC } },
    },
    skip: !config.enableSchedulePeriods,
  });
  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: !config.enableManagementByZone,
      variables: {
        data: { filters: { [LocationSetsFilters.CustomSets]: false }, order: { name: OrderDirection.ASC } },
      },
    },
  );
  const zoneItems = zonesData?.getLocationSets.items ? [nationalSelectItem, ...zonesData.getLocationSets.items] : [];
  const isNedOn = useFeatureFlag(Feature.Ned);
  let campaignTypes = Object.values(CampaignType).map((type) => {
    return { id: type, name: CampaignTypeLabel[type] };
  });

  if (!isNedOn) {
    campaignTypes = campaignTypes.filter((campaignType) => campaignType.id !== CampaignType.Ned);
  }

  useEffect(() => {
    loadTags();
    loadOfferBanks();
    loadBaseOfferRecs();
  }, []);

  return (
    <Container>
      <IconWrapper>
        <FilterIcon name="filter" />
      </IconWrapper>
      <Filters>
        <StyledFilterBarSelect
          name="status"
          disabled={forceStatusFilter?.length > 0}
          placeholder="Select"
          label="Status"
          multiple
          items={
            CampaignApprovalStatuses.filter((status) => {
              if (offerSource === OfferSource.DOE) {
                const excludedStatuses = [
                  ApprovalStatus.AssociationStopped,
                  ApprovalStatus.Approved,
                  ApprovalStatus.Draft,
                  ApprovalStatus.Rejected,
                  ApprovalStatus.PendingApproval,
                ];
                return !excludedStatuses.includes(status);
              } else {
                const excludedStatuses = [ApprovalStatus.AssociationStopped, ApprovalStatus.DeploymentPending];
                return !excludedStatuses.includes(status);
              }
            }).map((status) => {
              return { id: status, name: CampaignApprovalStatusLabel[status] };
            }) as any
          }
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Status,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={
            forceStatusFilter && forceStatusFilter.length > 0
              ? forceStatusFilter
              : filters[CampaignFilterType.Status] ?? []
          }
          maxItems={1}
          selectWidth={160}
        />
        {offerSource === OfferSource.VCE && (
          <StyledFilterBarSelect
            placeholder="Select"
            name="type"
            label="Type"
            multiple
            items={campaignTypes as any[]}
            onChange={(selectedItems: any) => {
              filter(
                CampaignFilterType.Type,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={filters[CampaignFilterType.Type] ?? []}
            reset
            maxItems={1}
            selectWidth={160}
          />
        )}
        <StyledFilterBarSelect
          name="tags"
          key={`${Boolean(tags.length)}`}
          placeholder="Select"
          label="Tags"
          multiple
          items={tags}
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Tags,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={tags?.length ? filters[CampaignFilterType.Tags] ?? [] : []}
          withSearch
          withAmount
          reset
          maxItems={1}
          selectWidth={160}
        />
        <StyledFilterBarSelect
          placeholder="Select"
          label="Alert"
          name="alert"
          multiple
          items={
            Object.values(CampaignAlert).map((alert) => {
              return { id: alert, name: CampaignAlertLabel[alert] };
            }) as any
          }
          onChange={(selectedItems: any) => {
            filter(
              CampaignFilterType.Alert,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={filters[CampaignFilterType.Alert] ?? []}
          reset
          maxItems={1}
          selectWidth={160}
        />
        {offerSource === OfferSource.DOE && (
          <>
            <StyledFilterBarSelect
              placeholder="Select"
              name="offerGroup"
              label="Offer Group"
              multiple
              items={
                Object.values(baseOfferRecs as Record<string, BaseOfferRec>).map((rec: BaseOfferRec) => {
                  return {
                    id: rec.offerGroupId,
                    name: rec.offerGroupId,
                  };
                }) as any
              }
              onChange={(selectedItems: BaseOfferRec[]) => {
                filter(
                  CampaignFilterType.OfferGroup,
                  selectedItems.map((i) => i.id),
                );
              }}
              initialSelectedItems={filters[CampaignFilterType.OfferGroup] ?? []}
              reset
              maxItems={1}
              selectWidth={160}
            />
            <StyledFilterBarSelect
              placeholder="Select"
              name="offerBank"
              label="Offer Bank"
              multiple
              items={
                Object.values(offerBanks as Record<string, OfferBank>).map((offerBank: OfferBank) => {
                  return {
                    id: offerBank.offerBankId,
                    name: offerBank.offerBankId,
                  };
                }) as any
              }
              onChange={(selectedItems: { id: string; name: string }[]) => {
                filter(
                  CampaignFilterType.OfferBank,
                  selectedItems.map((item) => item.id),
                );
              }}
              initialSelectedItems={filters[CampaignFilterType.OfferBank] ?? []}
              reset
              maxItems={1}
              selectWidth={160}
            />
          </>
        )}
        <MarketConfigurationGuard
          configurations={[{ configKey: MarketConfigurationKey.EnableSchedulePeriods, value: true }]}
        >
          {periodsData?.getPeriods?.items?.length > 0 && (
            <StyledFilterBarSelect
              placeholder="Select"
              name="periods"
              label="Periods"
              multiple
              items={periodsData.getPeriods.items}
              onChange={(selectedItems: any) => {
                filter(
                  CampaignFilterType.Period,
                  Object.values(selectedItems).map((i: any) => i.id),
                );
              }}
              initialSelectedItems={filters[CampaignFilterType.Period] ?? []}
              reset
              maxItems={1}
              selectWidth={160}
            />
          )}
        </MarketConfigurationGuard>
        {offerSource === OfferSource.VCE && (
          <MarketConfigurationGuard
            configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
          >
            {zonesData?.getLocationSets?.items?.length > 0 && (
              <StyledFilterBarSelect
                placeholder="Select"
                name="zone"
                label="Zone"
                multiple
                items={zoneItems}
                onChange={(selectedItems: any) => {
                  onCampaignZoneChange(selectedItems, filters, filterMulti);
                }}
                initialSelectedItems={getInitialCampaignZones(filters)}
                reset
                maxItems={1}
                selectWidth={160}
              />
            )}
          </MarketConfigurationGuard>
        )}
        {offerSource === OfferSource.VCE && (
          <MarketConfigurationGuard
            configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
          >
            <StyledCheckbox
              label="Only Bulk Campaigns"
              checked={filters[CampaignFilterType.OnlyLocal] === 'true'}
              onClick={(e) => {
                filter(CampaignFilterType.OnlyLocal, filters[CampaignFilterType.OnlyLocal] !== 'true');
                e.stopPropagation();
              }}
            />
          </MarketConfigurationGuard>
        )}
        <StyledSearch
          key={`${JSON.stringify(filters[CampaignFilterType.SearchQuery])}_SearchQuery`}
          name="campaigns-search"
          caretPosition={caretPosition}
          value={filters[CampaignFilterType.SearchQuery] ?? ''}
          onChange={(e) => {
            filter(CampaignFilterType.SearchQuery, e.target.value);
            setCaretPosition(e);
            store.dispatch(
              vceCampaignsPage.actions.setOrder(
                e.target.value?.length ? { _score: OrderDirection.DESC } : { externalId: OrderDirection.DESC },
              ),
            );
          }}
        />
        <ToggleSwitch
          label="List By Campaign Priority"
          disabled={offerSource === OfferSource.DOE}
          checked={filters[CampaignFilterType.Priority] === 'true'}
          onClick={() => handleToggle(filters, filterMulti, filterWithClear, onSortModelChange)}
          size="large"
          labelPosition="after"
        />
        <ButtonText
          onClick={() => {
            resetFilters(filterWithClear, onSortModelChange);
          }}
        >
          Restore Defaults
        </ButtonText>
      </Filters>
    </Container>
  );
};

export default CampaignsFilterBar;
