import { gql } from '@apollo/client';

export const DoeGqls = {
  mutations: {
    bulkCampaignsRevokeOrStopAssociation: gql`
      mutation BulkCampaignsRevokeOrStopAssociation($data: BulkCampaignsRevokeOrStopAssociationDto!) {
        bulkCampaignsRevokeOrStopAssociation(data: $data){
          offerPropIds
        }
      }
    `,
  },
};
