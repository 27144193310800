import { useLazyQuery } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { offerBanksGqls } from 'pages/campaigns/doeCampaigns/offer.bank.gqls';
import { useEffect } from 'react';
import { OrderDirection } from 'utils/types';
import { FetchPolicies } from 'utils/types/common';

export const useOfferBanksQuery = (
  showErrorToast: true,
  filters?: any,
  order: any = { name: OrderDirection.ASC },
) => {
  const [load, { data: { getOfferBanks: offerBanks = [] } = {}, loading, error }] =
    useLazyQuery(offerBanksGqls.queries.getAll, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters,
          order,
          path: 'offerBanks',
          attribute: 'offerBankId',
        },
      },
    });

  useEffect(() => {
    if (error && showErrorToast) {
      showToast(MessageType.Error, 'Error loading offer banks');
    }
  }, [error, showErrorToast]);

  return { load, offerBanks, loading };
};
