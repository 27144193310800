import { gql } from '@apollo/client';

export const baseOfferRecsGqls = {
  queries: {
    getAll: gql`
      query GetBaseOfferRecs {
        getBaseOfferRecs {
          id
          offerGroupId
        }
      }
    `
  }
};


