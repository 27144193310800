import { gql } from '@apollo/client';

export const offerBanksGqls = {
  queries: {
    getAll: gql`
      query GetOfferBanks {
        getOfferBanks {
          offerBankId
        }
      }
    `
  }
};


